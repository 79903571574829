<template>
  <!-- Modal -->
  <GDialog v-model="showCasualModal" max-width="600">
    <div class="modal-wrapper">
      <div class="modal-actions">
        <button class="modal-button" @click="showCasualModal = false">X</button>
      </div>
      <div class="modal-content">
        <div class="modal-title">Casual – {{ rates.casual.basic }}&nbsp;€ mtl.</div>
        <hr />
        <b>Gute Wahl!</b>
        <p>
          Hiermit schützt du deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
          Peripheriegeräte und Hardware zum Betreiben der Elektronik bis zu einem Gesamtwert von
          {{ coverage.casual }} Euro.
        </p>
      </div>
    </div>
  </GDialog>
  <!-- Modal end -->
  <!-- Modal -->
  <GDialog v-model="showHardcoreModal" max-width="600">
    <div class="modal-wrapper">
      <div class="modal-actions">
        <button class="modal-button" @click="showHardcoreModal = false">X</button>
      </div>
      <div class="modal-content">
        <div class="modal-title">Hardcore – {{ rates.hardcore.basic }}&nbsp;€ mtl.</div>
        <hr />
        <b>Hardcoremäßig abgesichert</b>
        <p>
          Mit diesem Tarif sind deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
          Peripheriegeräte und Hardware zum Betreiben der Elektronik bis zu einem Gesamtwert von
          {{ coverage.hardcore }} Euro geschützt.
        </p>
      </div>
    </div>
  </GDialog>
  <!-- Modal end -->
  <!-- Modal -->
  <GDialog v-model="showProfessionalModal" max-width="600">
    <div class="modal-wrapper">
      <div class="modal-actions">
        <button class="modal-button" @click="showProfessionalModal = false">X</button>
      </div>
      <div class="modal-content">
        <div class="modal-title">Professional – {{ rates.professional.basic }}&nbsp;€ mtl.</div>
        <hr />
        <b>Hier erkennt man den Profi!</b>
        <p>
          Nicht nur deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen, Peripheriegeräte
          und Hardware zum Betreiben der Elektronik sind geschützt, sondern
          <b>auch dein Streaming Setup, deine professionelle Beleuchtung und dein Gaming Chair</b> ab
          {{ coverage.chair }} Euro. Dein gesamtes Equipment ist bis zu einem Gesamtwert von
          {{ coverage.professional }} Euro abgesichert.
        </p>
      </div>
    </div>
  </GDialog>
  <!-- Modal end -->
  <div class="astep astep--0" v-if="astep == 0">
    <!-- EquipmentWahl -->
    <!-- input class="ruv-btn" type="button" v-on:click="push2astep(3)" value="Step" -->
    <!-- input class="ruv-btn" type="button" v-on:click="goto('person')" value="goto person">
    <input class="ruv-btn" type="button" v-on:click="goto('equip')" value="goto equip" -->
    <div class="section equip" id="equip" ref="equip">
      <h3 class="intertitle c--white"><br />Was ist versichert?</h3>
      <div class="notice no-border txt--small summary-fields checklist">
        <ul class="values">
          <li class="value value--check">PCs, Laptops, Spielkonsolen, Monitore/Fernseher</li>
          <li class="value value--check">
            Peripheriegeräte wie z.B. Gamepads, Mäuse, Tastaturen, Headsets oder VR-Brillen
          </li>
          <li class="value value--check">Originalzubehör und nachgerüstetes Zubehör</li>
          <li class="value value--check">
            Im Tarif Professional: Streaming Setup, professionelle Beleuchtung und Gaming Chairs ab 1.000 Euro
          </li>
          <li class="value value--check">
            Gaming-Equipment, das bei Abschluss der Versicherung nicht älter als 36 Monate und im Schadenfall nicht
            älter als 60 Monate ab Neukauf ist
          </li>
        </ul>
        <p class="txt txt--tiny txt--center">
          Nicht versichert sind Handys, Smartphones, Tablets, Smartwatches, Ausstellungsgeräte, reimportierte Geräte
          sowie Schäden durch Witterungseinflüsse und elementare Naturereignisse.
        </p>
      </div>
      <h3 class="intertitle c--white"><br />Wähle den passenden Schutz für dein Equipment!</h3>
      <div class="wrap">
        <label class="equip__item" :class="{ active: post.product.vtype == 'casual' }">
          <div class="wrap">
            <h4 class="preline txt--small">Equipment</h4>
            <div class="coverage txt--small">
              bis <span class="coverage__amount">{{ coverage.casual }}</span> €
            </div>
            <img class="icon" src="includes/media/icon_casual.svg" />
            <h2 class="title txt--small">Casual</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates.casual.basic }}</span> € mtl.
            </div>
            <div class="desc">
              <h6 class="desc__title">Casual</h6>
              Hiermit schützt du deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
              Peripheriegeräte und Hardware zum Betreiben der Elektronik bis zu einem Gesamtwert von
              {{ coverage.casual }} Euro.
            </div>
            <input type="radio" v-model="post.product.vtype" value="casual" />
            <i class="info" @click="showCasualModal = true">i</i>
          </div>
        </label>

        <label class="equip__item" :class="{ active: post.product.vtype == 'hardcore' }">
          <div class="wrap">
            <h4 class="preline txt--small">Equipment</h4>
            <div class="coverage txt--small">
              bis <span class="coverage__amount">{{ coverage.hardcore }}</span> €
            </div>
            <img class="icon" src="includes/media/icon_hardcore.svg" />
            <h2 class="title txt--small">Hardcore</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates.hardcore.basic }}</span> € mtl.
            </div>
            <div class="desc">
              <h6 class="desc__title">Hardcore</h6>
              Mit diesem Tarif sind deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
              Peripheriegeräte und Hardware zum Betreiben der Elektronik bis zu einem Gesamtwert von
              {{ coverage.hardcore }} Euro geschützt.
            </div>
            <input type="radio" v-model="post.product.vtype" value="hardcore" />
            <i class="info" @click="showHardcoreModal = true">i</i>
          </div>
        </label>

        <label class="equip__item" :class="{ active: post.product.vtype == 'professional' }">
          <div class="wrap">
            <h4 class="preline txt--small">Equipment</h4>
            <div class="coverage txt--small">
              bis <span class="coverage__amount">{{ coverage.professional }}</span> €
            </div>
            <img class="icon" src="includes/media/icon_professional.svg" />
            <h2 class="title txt--small">Professional</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates.professional.basic }}</span> € mtl.
            </div>
            <div class="desc">
              <h6 class="desc__title">Professional</h6>
              Nicht nur deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
              Peripheriegeräte und Hardware zum Betreiben der Elektronik sind geschützt, sondern auch dein Streaming
              Setup, deine professionelle Beleuchtung und dein Gaming Chair ab {{ coverage.chair }} Euro. Dein gesamtes
              Equipment ist bis zu einem Gesamtwert von {{ coverage.professional }} Euro abgesichert.
            </div>
            <input type="radio" v-model="post.product.vtype" value="professional" />
            <i class="info" @click="showProfessionalModal = true">i</i>
          </div>
        </label>
      </div>
    </div>
    <div class="section upgrade" id="upgrade">
      <h3 class="intertitle c--white">Mach dein Equipment noch sicherer!</h3>
      <div class="wrap">
        <label class="upgrade__item" :class="{ active: post.product.vadds.technology }">
          <div class="wrap">
            <h2 class="title txt--small">Technology</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates[post.product.vtype].technology }}</span
              >&nbsp;€ mtl.
            </div>
            <div class="desc txt--small">
              Zuschuss i. H. v. 10 % des Schadens für den Einbau technologisch fortgeschrittener Teile im Reparaturfall.
            </div>
            <input
              type="checkbox"
              v-model="post.product.vadds.technology"
              @click="eqipmentclick('technology', post.product.vadds.technology)"
              value="true" />
          </div>
        </label>
        <label class="upgrade__item" :class="{ active: post.product.vadds.verschleiss }">
          <div class="wrap">
            <h2 class="title txt--small">Verschleiss</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates[post.product.vtype].verschleiss }}</span
              >&nbsp;€ mtl.
            </div>
            <div class="desc txt--small">
              Verschleiß bzw. innere Betriebsschäden sind bis zu 150€ pro Versicherungsfall und pro Versicherungsjahr
              versichert.
            </div>
            <input
              type="checkbox"
              v-model="post.product.vadds.verschleiss"
              value="true"
              @click="eqipmentclick('verschleiss', post.product.vadds.verschleiss)" />
          </div>
        </label>
        <label class="upgrade__item" :class="{ active: post.product.vadds.transport }">
          <div class="wrap">
            <h2 class="title txt--small">Transport</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates[post.product.vtype].transport }}</span
              >&nbsp;€ mtl.
            </div>
            <div class="desc txt--small">
              Absicherung gegen Schäden an der Elektronik während des privaten Transports.
            </div>
            <input
              type="checkbox"
              v-model="post.product.vadds.transport"
              @click="eqipmentclick('transport', post.product.vadds.transport)"
              value="true" />
          </div>
        </label>
        <label class="upgrade__item" :class="{ active: post.product.vadds.handhabung }">
          <div class="wrap">
            <h2 class="title txt--small">Unsachgemäße Handhabung</h2>
            <div class="price txt--small">
              <span class="price__amount">{{ rates[post.product.vtype].handhabung }}</span
              >&nbsp;€ mtl.
            </div>
            <div class="desc txt--small">Schützt u. a. gegen Schäden aufgrund falscher Bedienung der Elektronik.</div>
            <input
              type="checkbox"
              v-model="post.product.vadds.handhabung"
              @click="eqipmentclick('handhabung', post.product.vadds.handhabung)"
              value="true" />
          </div>
        </label>
        <div class="notice no-border txt--tiny txt--center">
          <br />
          <br />
          Die R+V Hardwareversicherung bietet Versicherungsschutz für eine Vielzahl von Schadenereignissen. Manche
          Schadenereignisse sind u. U. bereits in einer bestehenden Hausratversicherung versichert. Prüfe daher eine
          mögliche Doppelversicherung.
        </div>
      </div>
    </div>
    <div class="section" id="payment-interval" ref="payment">
      <h3 class="intertitle c--white">Wähle das für dich angenehmste Zahlungsintervall.</h3>
      <div class="wrap">
        <label class="select">
          <select v-model="post.payment.month_interval" id="paymentinterval" class="paymentinterval txt--small">
            <option value="2" selected>MONATLICH</option>
            <option value="3">VIERTELJÄHRLICH</option>
            <option value="4">HALBJÄHRLICH</option>
            <option value="5">JÄHRLICH</option>
          </select>
        </label>
      </div>
    </div>
    <div class="section" id="schedule" ref="schedule">
      <h3 class="intertitle c--white">Wann soll dein Schutz beginnen?</h3>
      <div class="wrap">
        <label class="datetime">
          <div class="ruv_datpicker">
            <Datepicker
              :locale="locale"
              name="datetime"
              id="datetime"
              placeholder="TT.MM.JJJJ"
              v-model="dates.coverageStartDate"
              inputFormat="dd.MM.yyyy"
              :upperLimit="dates.insurance_to"
              :lowerLimit="dates.insurance_from"
              :clearable="false" /><i class="fa-solid fa-calendar-days clickable fordatepicker"></i>
          </div>
          <span class="notice no-border txt--tiny c--white">
            <br />Die Mindestvertragslaufzeit beträgt 1 Jahr und kann zum Ende der Mindestvertragslaufzeit mit einer
            Frist von einem Monat erstmalig gekündigt werden. Nach der Mindestvertragslaufzeit kann der Vertrag
            monatlich mit einer Frist von einem Monat gekündigt werden.
          </span>
        </label>
      </div>
    </div>
    <div class="section" id="birthday" ref="birthday">
      <h3 class="intertitle c--white">Wann hast Du Geburtstag?</h3>
      <div class="wrap">
        <label class="datetime">
          <div class="ruv_datpicker">
            <Datepicker
              @click="birthdayclick()"
              :locale="locale"
              name="birthdate"
              id="birthdate"
              placeholder="TT.MM.JJJJ"
              v-model="dates.birthdate"
              format="dd.MM.yyyy"
              inputFormat="dd.MM.yyyy"
              :upperLimit="dates.birthdate_to"
              :clearable="false" /><i class="fa-solid fa-calendar-days clickable fordatepicker"></i>
          </div>
          <span class="notice no-border txt--small c--white"> Du musst mindestens 18 Jahre alt sein. </span>
        </label>
      </div>
    </div>
    <div class="section" id="person" ref="person">
      <h3 class="intertitle c--white">Gleich geschafft! Wir brauchen noch ein paar Angaben von Dir.</h3>
      <div class="wrap">
        <form id="Tarifrechner" ref="form">
          <div class="fields">
            <label class="field fname">
              <select v-model="post.person.salutation" id="paymentinterval" class="paymentinterval txt--small">
                <option value="Frau">Frau</option>
                <option v-if="diverse" value="Divers">🌈 Divers</option>
                <option value="Herr">Herr</option>
              </select>
            </label>
            <label class="field fname">
              <input
                type="text"
                title="Bitte gib Deinen Vornamen an"
                v-model="post.person.firstName"
                class="form-control ruv_clear_input"
                id="vorname"
                placeholder="Vorname*"
                required />
              <p v-show="errors.vorname" class="errors">
                <span v-show="errors.vorname" class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field lname">
              <input
                type="text"
                v-model="post.person.lastName"
                title="Bitte gib Deinen Nachnamen an"
                class="form-control ruv_clear_input"
                id="nachname"
                placeholder="Nachname*"
                required />
              <p v-show="errors.nachname" class="errors">
                <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field street">
              <input
                type="text"
                v-model="post.person.street"
                title="Bitte gib Deine Straße an"
                class="form-control ruv_clear_input"
                id="strasse"
                placeholder="Straße*"
                required />
              <p v-show="errors.strasse" class="errors">
                <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field streetno">
              <input
                type="text"
                v-model="post.person.houseNumber"
                title="Bitte gib Deine Hausnummer an"
                class="form-control ruv_clear_input"
                id="nummer"
                placeholder="Nr.*"
                required />
              <p v-show="errors.nummer" class="errors">
                <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field zipcode">
              <input
                type="numeric"
                pattern="[0-9]{5}"
                title="Bitte exakt 5 Ziffern eingeben!"
                minlength="5"
                maxlength="5"
                v-model="post.person.zipCode"
                class="form-control ruv_clear_input"
                id="plz"
                placeholder="PLZ*"
                required />
              <p v-show="errors.plz" class="errors">
                <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field city">
              <input
                type="text"
                v-model="post.person.city"
                title="Bitte gib Deinen Wohnort an"
                class="form-control ruv_clear_input"
                id="ort"
                placeholder="Ort*"
                required />
              <p v-show="errors.ort" class="errors">
                <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field nationality">
              <select v-model="post.person.nationality" class="paymentinterval txt--small" id="nationality" required>
                <option disabled value="">Bitte Staatsangehörigkeit wählen*</option>
                <option v-for="(item, index) in nationalities" v-bind:key="index" :value="index">
                  {{ item }}
                </option>
              </select>
              <p v-show="errors.nationality" class="errors">
                <span class="errors--0 txt--small">Die Auswahl darf nicht leer sein.</span>
              </p>
            </label>
            <label class="field phone">
              <input
                type="text"
                v-model="post.person.phoneNumber"
                title="Bitte gib, wenn Du das magst Dein Telefonnummer an"
                class="form-control ruv_clear_input"
                placeholder="Telefonnummer" />
            </label>
            <label class="field email">
              <input
                :disabled="rework"
                type="email"
                v-model="post.person.email"
                title="Bitte gib Deine E-Mail Adresse an"
                class="form-control ruv_clear_input"
                id="email"
                placeholder="E-Mail*"
                required />
              <p v-show="errors.email" class="errors">
                <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
              </p>
            </label>
          </div>
          <span class="notice no-border txt--small c--white"> Mit * gekennzeichnete Felder sind Pflichtfelder. </span>
          <!-- input type="button" class="ruv-btn" v-on:click="seite('Zahlungsdingsi')" value="Neueseite" -->
          <input type="button" v-if="!rework" class="ruv-btn" v-on:click="submit" value="E-MAIL BESTÄTIGEN" />
          <input
            type="button"
            v-if="rework"
            class="ruv-btn"
            v-on:click="
              productid();
              push2astep(3);
            "
            value="DATEN BESTÄTIGEN" />
          <p class="notice no-border txt--small txt--center c--white">
            Bei Klick auf „E-MAIL BESTÄTIGEN“ wird eine E-Mail an deine angegebene E-Mail-Adresse versandt. Diese E-Mail
            beinhaltet neben der Erstinformation die vorvertraglichen Unterlagen (u.a. Versicherungsbedingungen und
            Kundeninformation, Produktinformationsblatt, Vertragsinformationen inkl. Widerrufsbelehrung,
            Datenschutzerklärung).<br />
            <br />
            In der E-Mail befindet sich ein Bestätigungsbutton über den du zurück zur Beantragung deiner R+V
            Hardwareversicherung gelangst.<br />
            <br />
            Bitte prüfe auch deinen SPAM-Ordner, falls die Unterlagen in den nächsten Minuten nicht bei dir
            eintreffen.<br /><br />
            <br />
            <br />
            <br />
            <br />
          </p>
        </form>
      </div>
    </div>

    <div id="ruv-sidebar">
      <div class="wrap">
        <h4 class="sidebar__title">
          <span style="font-weight: bold"> Dein <br class="big-only" />Schutz </span>
          <span class="txt--tiny">(inkl. 19% Versicherungssteuer)</span>
        </h4>
        <div class="sidebar__price">
          <span class="price__amount" style="font-weight: bold">{{ getPriceAmount }} €</span>
          <br class="big-only" />
          <span class="txt--small" v-if="post.payment.month_interval == 2"> mtl.</span>
          <span class="txt--small" v-if="post.payment.month_interval == 3"> vierteljährl.</span>
          <span class="txt--small" v-if="post.payment.month_interval == 4"> halbjährl.</span>
          <span class="txt--small" v-if="post.payment.month_interval == 5"> jährl.</span>
        </div>
        <hr class="divider divider--blue" />
        <h4 class="txt--small subtitle big-only">Tarif</h4>
        <li class="product__position txt--small">
          <h5 class="title">
            <span id="sidebar-position-casual" class="hidden" :class="{ show: post.product.vtype == 'casual' }"
              >Casual
              <i class="info" @click="showCasualModal = true">i</i>
            </span>
            <span id="sidebar-position-hardcore" class="hidden" :class="{ show: post.product.vtype == 'hardcore' }"
              >Hardcore
              <i class="info" @click="showHardcoreModal = true">i</i>
            </span>
            <span
              id="sidebar-position-Professional"
              class="hidden"
              :class="{ show: post.product.vtype == 'professional' }"
              >Professional
              <i class="info" @click="showProfessionalModal = true">i</i>
            </span>
          </h5>
          <div class="price">
            <span class="price__amount hidden" :class="{ show: post.product.vtype == 'casual' }">{{
              rates.casual.basic
            }}</span>
            <span class="price__amount hidden" :class="{ show: post.product.vtype == 'hardcore' }">{{
              rates.hardcore.basic
            }}</span>
            <span class="price__amount hidden" :class="{ show: post.product.vtype == 'professional' }">{{
              rates.professional.basic
            }}</span>
            € mtl.
          </div>
        </li>
        <h4
          v-if="
            post.product.vadds.technology ||
            post.product.vadds.verschleiss ||
            post.product.vadds.transport ||
            post.product.vadds.handhabung
          "
          class="txt--small subtitle big-only">
          Tarifupgrade
        </h4>
        <li class="product__position txt--small" v-if="post.product.vadds.technology">
          <h5 class="title">Technology</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].technology }}</span> € mtl.
          </div>
        </li>
        <li class="product__position txt--small" v-if="post.product.vadds.verschleiss">
          <h5 class="title">Verschleiss</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].verschleiss }}</span> € mtl.
          </div>
        </li>
        <li class="product__position txt--small" v-if="post.product.vadds.transport">
          <h5 class="title">Transport</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].transport }}</span> € mtl.
          </div>
        </li>
        <li class="product__position txt--small" v-if="post.product.vadds.handhabung">
          <h5 class="title">Handhabung</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].handhabung }}</span> € mtl.
          </div>
        </li>
        <h4 class="txt--small subtitle big-only">Zahlungsintervall</h4>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 2">Monatlich</p>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 3">Vierteljährlich</p>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 4">Halbjährlich</p>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 5">Jährlich</p>
        <h4 class="txt--small subtitle big-only">Versicherungsbeginn</h4>
        <p class="txt--small big-only">{{ formatDate(dates.coverageStartDate) }}</p>
        <h4 class="txt--small subtitle big-only">Vertragslaufzeit</h4>
        <p class="txt--small big-only">1 Jahr</p>
      </div>
    </div>
  </div>
  <div class="astep astep--1" v-if="astep == 1">
    <!-- E-Mail Versand -->
    <div class="section">
      <div class="wrap txt--center">
        <p class="c--white txt--small">
          <br class="mobile-only" />
          <br />
          <br />
          <br />
          <br />
          <br class="mobile-only" />
          Wir haben dir eine E-Mail an deine angegebene E-Mail-Adresse gesandt. In der E-Mail befindet sich ein
          Bestätigungsbutton über den du zurück zum Abschluss der R+V Hardwareversicherung gelangst.<br />
          <br />
          Ebenfalls beinhaltet die E-Mail neben der Erstinformation die vorvertraglichen Unterlagen (u.a.
          Versicherungsbedingungen und Kundeninformation, Produktinformationsblatt, Vertragsinformationen inkl.
          Widerrufsbelehrung, Datenschutzerklärung).<br />
          <br />
          Bitte prüfe auch deinen SPAM-Ordner, falls die Unterlagen in den nächsten Minuten nicht bei dir eintreffen.
          <br /><br /><br />
        </p>
        <hr class="divider--white" />
      </div>
    </div>
  </div>
  <div class="astep astep--2" v-if="astep == 2">
    <!-- Kontoverbindung -->
    <div class="section">
      <!-- form id="Zahlungsdaten" ref="zahlform" -->
      <p class="c--white txt--tiny txt--center">
        &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
      </p>
      <h1 class="txt--big txt--center c--white">Willkommen zurück!</h1>
      <h3 class="intertitle c--white">Bitte gib deine Kontoverbindung an.</h3>
      <div class="wrap">
        <div class="fields">
          <label class="field accountholder">
            <input
              type="text"
              id="accountholder"
              v-model="post.payment.fullname"
              placeholder="Kontoinhaber*in"
              required />
            <p v-show="errors.fullname" class="errors">
              <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
            </p>
          </label>
          <label class="field iban">
            <input type="text" v-model="post.payment.iban" id="iban" placeholder="IBAN*" required />
            <p v-show="errors.iban" class="errors">
              <span class="errors--0 txt--small">Das Feld darf nicht leer sein.</span>
            </p>
            <p v-show="errors.ibaninvalid" class="errors">
              <span class="errors--0 txt--small">Die IBAN ist nicht gültig.</span>
            </p>
          </label>
        </div>
        <span class="notice no-border txt--small c--white"> Mit * gekennzeichnete Felder sind Pflichtfelder </span>
        <div class="fields">
          <label class="upgrade__item sepa" :class="{ active: post.payment.sepa }">
            <div class="wrap">
              <div class="desc txt--small">Ja, ich erteile das SEPA-Lastschriftmandat.</div>
              <input type="checkbox" v-model="post.payment.sepa" required />
              <p v-show="errors.sepa" class="errors">
                <span class="errors--0 txt--small">Das Feld bitte anklicken.</span>
              </p>
            </div>
          </label>
        </div>
        <p class="c--white txt--tiny txt--center">
          Ich ermächtige die assona GmbH (Gläubiger-ID: DE- 89BER00000108559), Zahlungen gemäß der vereinbarten
          Zahlweise von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von
          der assona GmbH auf meinem Konto gezogenen Lastschriften einzulösen. Die Mandatsreferenznummer entspricht
          hierbei der Vertragsnummer.<br />
          <br />
          Hinweis: Du kannst innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten
          Betrags verlangen. Es gelten dabei die mit deinem Kreditinstitut vereinbarten Bedingungen.
        </p>
        <input type="button" @click="checkiban(post.payment.iban)" class="ruv-btn" value="ZUSAMMENFASSUNG" /><br />
        <p class="c--white txt--tiny txt--center">
          &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
        </p>
      </div>
      <!-- /form -->
    </div>
    <div id="ruv-sidebar">
      <div class="wrap">
        <h4 class="sidebar__title">
          <span style="font-weight: bold"> Dein <br class="big-only" />Schutz </span>
          <span class="txt--tiny">(inkl. 19% Versicherungssteuer)</span>
        </h4>
        <div class="sidebar__price">
          <span class="price__amount" style="font-weight: bold">{{ getPriceAmount }} €</span>
          <br class="big-only" />
          <span class="txt--small" v-if="post.payment.month_interval == 2"> mtl.</span>
          <span class="txt--small" v-if="post.payment.month_interval == 3"> vierteljährl.</span>
          <span class="txt--small" v-if="post.payment.month_interval == 4"> halbjährl.</span>
          <span class="txt--small" v-if="post.payment.month_interval == 5"> jährl.</span>
        </div>
        <hr class="divider divider--blue" />
        <h4 class="txt--small subtitle big-only">Tarif</h4>
        <li class="product__position txt--small">
          <h5 class="title">
            <span id="sidebar-position-casual" class="hidden" :class="{ show: post.product.vtype == 'casual' }"
              >Casual
              <i class="info" @click="showCasualModal = true">i</i>
            </span>
            <span id="sidebar-position-hardcore" class="hidden" :class="{ show: post.product.vtype == 'hardcore' }"
              >Hardcore
              <i class="info" @click="showHardcoreModal = true">i</i>
            </span>
            <span
              id="sidebar-position-Professional"
              class="hidden"
              :class="{ show: post.product.vtype == 'professional' }"
              >Professional
              <i class="info" @click="showProfessionalModal = true">i</i>
            </span>
          </h5>
          <div class="price">
            <span class="price__amount hidden" :class="{ show: post.product.vtype == 'casual' }">{{
              rates.casual.basic
            }}</span>
            <span class="price__amount hidden" :class="{ show: post.product.vtype == 'hardcore' }">{{
              rates.hardcore.basic
            }}</span>
            <span class="price__amount hidden" :class="{ show: post.product.vtype == 'professional' }">{{
              rates.professional.basic
            }}</span>
            € mtl.
          </div>
        </li>
        <h4
          v-if="
            post.product.vadds.technology ||
            post.product.vadds.verschleiss ||
            post.product.vadds.transport ||
            post.product.vadds.handhabung
          "
          class="txt--small subtitle big-only">
          Tarifupgrade
        </h4>
        <li class="product__position txt--small" v-if="post.product.vadds.technology">
          <h5 class="title">Technology</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].technology }}</span> € mtl.
          </div>
        </li>
        <li class="product__position txt--small" v-if="post.product.vadds.verschleiss">
          <h5 class="title">Verschleiss</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].verschleiss }}</span> € mtl.
          </div>
        </li>
        <li class="product__position txt--small" v-if="post.product.vadds.transport">
          <h5 class="title">Transport</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].transport }}</span> € mtl.
          </div>
        </li>
        <li class="product__position txt--small" v-if="post.product.vadds.handhabung">
          <h5 class="title">Handhabung</h5>
          <div class="price">
            <span class="price__amount">{{ rates[post.product.vtype].handhabung }}</span> € mtl.
          </div>
        </li>
        <h4 class="txt--small subtitle big-only">Zahlungsintervall</h4>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 2">Monatlich</p>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 3">Vierteljährlich</p>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 4">Halbjährlich</p>
        <p class="txt--small big-only" v-if="post.payment.month_interval == 5">Jährlich</p>
        <h4 class="txt--small subtitle big-only">Versicherungsbeginn</h4>
        <p class="txt--small big-only">{{ formatDate(dates.coverageStartDate) }}</p>
        <h4 class="txt--small subtitle big-only">Vertragslaufzeit</h4>
        <p class="txt--small big-only">1 Jahr</p>
      </div>
    </div>
    <!-- Modal -->
    <GDialog v-model="showIbanModal" max-width="600">
      <div class="modal-wrapper">
        <div class="modal-actions">
          <button class="modal-button" @click="showIbanModal = false">X</button>
        </div>
        <div class="modal-content">
          <div class="modal-title"><b>MUSTERTEXT!!! Bitte gib die korrekte IBAN an!!! MUSTERTEXT</b></div>
          <hr />
          <p>
            Wir brauchen eine korrekte IBAN und einen vollen Namen zu dem entsprechenden Konto von dem Abgebucht werden
            soll.<br />
            Hinweis: Du kannst innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des
            belasteten Betrags verlangen. Es gelten dabei die mit deinem Kreditinstitut vereinbarten Bedingungen.<br />
            Bitte stimme dem SEPA Lastschriftmandat zu.
          </p>
        </div>
      </div>
    </GDialog>
    <!-- Modal end -->
  </div>
  <div class="astep astep--3" v-if="astep == 3">
    <!-- Zusammenfassung -->
    <div class="section">
      <h3 class="intertitle c--white">
        <br class="mobile-only" />
        <br class="mobile-only" />
        Zusammenfassung
      </h3>
      <div class="wrap">
        <div class="summary-fields txt--small c--white">
          <div
            class="toggle-fieldset"
            @click="
              push2astep(0);
              rework = 'true';
              goto('equip');
            "
            data-ref="0"></div>
          <div>
            <h6 class="title">Tarif</h6>
            <ul class="values">
              <li class="value">
                <span class="toCapitalFirst txt--regular">{{ post.product.vtype }}</span>
              </li>
            </ul>
            <div class="txt--regular" v-if="post.product.vtype == 'casual'">Equipment bis {{ coverage.casual }} €</div>
            <div class="txt--regular" v-if="post.product.vtype == 'hardcore'">
              Equipment bis {{ coverage.hardcore }} €
            </div>
            <div class="txt--regular" v-if="post.product.vtype == 'professional'">
              Equipment bis {{ coverage.professional }} €
            </div>
            <div class="desc" v-if="post.product.vtype == 'casual'">
              Hiermit schützt du deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
              Peripheriegeräte und Hardware zum Betreiben der Elektronik bis zu einem Gesamtwert von
              {{ coverage.casual }} Euro.
            </div>
            <div class="desc" v-if="post.product.vtype == 'hardcore'">
              Mit diesem Tarif sind deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
              Peripheriegeräte und Hardware zum Betreiben der Elektronik bis zu einem Gesamtwert von
              {{ coverage.hardcore }} Euro geschützt.
            </div>
            <div class="desc" v-if="post.product.vtype == 'professional'">
              Nicht nur deine Basisgeräte wie Monitore, Fernseher, Desktop-PCs, Laptops, Spielekonsolen,
              Peripheriegeräte und Hardware zum Betreiben der Elektronik sind geschützt, sondern auch dein Streaming
              Setup, deine professionelle Beleuchtung und dein Gaming Chair ab
              {{ coverage.chair }} Euro. Dein gesamtes Equipment ist bis zu einem Gesamtwert von
              {{ coverage.professional }} Euro abgesichert.
            </div>
          </div>
          <div>
            <h6
              class="title"
              v-if="
                post.product.vadds.technology ||
                post.product.vadds.verschleiss ||
                post.product.vadds.transport ||
                post.product.vadds.handhabung
              ">
              <br />Tarifupgrade
            </h6>
            <ul class="values">
              <li class="value value--check" v-if="post.product.vadds.technology">
                <span class="hook">Technology</span>
              </li>
              <li class="value value--check" v-if="post.product.vadds.verschleiss">
                <span class="hook">Verschleiß</span>
              </li>
              <li class="value value--check" v-if="post.product.vadds.transport">
                <span class="hook">Transport</span>
              </li>
              <li class="value value--check" v-if="post.product.vadds.handhabung">
                <span class="hook">Unsachgemässe Handhabung</span>
              </li>
            </ul>
          </div>
          <div>
            <h6 class="title">Beitrag</h6>
            <ul class="values">
              <li class="value">
                <span>{{ getPriceAmount }}</span> €
                <span v-if="post.payment.month_interval == 2">mntl.</span>
                <span v-if="post.payment.month_interval == 3">vierteljährl.</span>
                <span v-if="post.payment.month_interval == 4">halbjährl.</span>
                <span v-if="post.payment.month_interval == 5">jährl.</span>
                inkl. 19 % VersSt.
              </li>
            </ul>
          </div>
          <div>
            <h6 class="title">Vertragslaufzeit</h6>
            <ul class="values">
              <li class="value"><span>1 Jahr</span></li>
            </ul>
          </div>
          <div>
            <h6 class="title">Zahlungsintervall</h6>
            <ul class="values">
              <li class="value" v-if="post.payment.month_interval == 2"><span>Monatlich</span></li>
              <li class="value" v-if="post.payment.month_interval == 3"><span>Vierteljährlich</span></li>
              <li class="value" v-if="post.payment.month_interval == 4"><span>Halbjährlich</span></li>
              <li class="value" v-if="post.payment.month_interval == 5"><span>Jährlich</span></li>
            </ul>
          </div>
          <div>
            <h6 class="title">Versicherungsbeginn</h6>
            <ul class="values">
              <li class="value">
                <span>{{ formatDate(dates.coverageStartDate) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="summary-fields txt--small c--white">
          <div class="toggle-fieldset" @click="push2astep(2)" data-ref="2"></div>
          <div>
            <h6 class="title">Kontoinhaber*in</h6>
            <ul class="values">
              <li class="value">
                <span>{{ post.payment.fullname }}</span>
              </li>
            </ul>
          </div>
          <div>
            <h6 class="title">IBAN</h6>
            <ul class="values">
              <li class="value">
                <span>{{ post.payment.iban }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="summary-fields txt--small c--white">
          <div
            class="toggle-fieldset"
            @click="
              push2astep(0);
              rework = 'true';
              goto('person');
            "
            data-ref="0"></div>
          <div>
            <h6 class="title">Persönliche Daten</h6>
            <ul class="values">
              <li class="value">
                <span>{{ post.person.firstName }} {{ post.person.lastName }}</span>
              </li>
              <li class="value">
                <span>{{ post.person.street }} {{ post.person.houseNumber }}</span>
              </li>
              <li class="value">
                <span>{{ post.person.zipCode }} {{ post.person.city }}</span>
              </li>
              <li class="value">
                <span>{{ ucfirst(post.person.email) }}</span>
              </li>
              <li class="value">
                <span>{{ ucfirst(nationalities[post.person.nationality]) }}</span>
              </li>
              <li class="value">
                <span>{{ post.person.dateOfBirth }}</span>
              </li>
            </ul>
          </div>
        </div>
        <p class="c--white txt--small txt--center"></p>
        <!-- hr class="divider--green" -->
        <div class="downloads">
          <a
            href="docs/Vertragsunterlagen_Hardware-Versicherung.pdf"
            target="_blank"
            download
            class="download txt--small c--white"
            >Vertragsunterlagen<br />
            <span class="txt--tiny">
              (Versicherungsbedingungen und Kundeninformation, Produktinformationsblatt, Vertragsinformationen inkl.
              Widerrufsbelehrung)
            </span>
          </a>
          <a
            href="docs/Datenschutz_Hardware-Versicherung.pdf"
            target="_blank"
            download
            class="download txt--small c--white"
            >Merkblatt zur Datenverarbeitung</a
          >
        </div>
        <input
          type="button"
          @click="sealthedeal()"
          id="purchase"
          class="ruv-btn"
          value="BEITRAGSPFLICHTIG BEANTRAGEN" />
      </div>
    </div>
  </div>
  <div class="astep astep--4" v-if="astep == 4">
    <!-- Datenübermittlung -->
    <div class="section">
      <h3 class="intertitle c--white">
        <br />
        <br />
        <br class="mobile-only" />
        Deine Daten werden übermittelt
      </h3>
      <div class="wrap">
        <!-- input type="button" class="ruv-btn" v-on:click="push2astep(3)" value="Step" -->
        <div id="preloader">
          <div class="spinner">
            <img src="includes/media/spinner.svg" />
          </div>
          <h3 class="intertitle c--white">Deine Daten werden übermittelt</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="astep astep--5" v-if="astep == 5">
    <!-- Dankesseite -->
    <div class="section">
      <h3 class="intertitle c--white">
        <br />
        <br />
        <br class="mobile-only" />
        Vielen Dank für Dein Vertrauen!
      </h3>
      <div class="wrap txt--center">
        <p class="c--white txt--small">
          Schön, dass du dich für die R+V Hardware-Versicherung
          <span v-if="post.product.vtype == 'casual'">CASUAL</span>
          <span v-if="post.product.vtype == 'hardcore'">HARDCORE</span>
          <span v-if="post.product.vtype == 'professional'">PROFESSIONAL</span>
          entschieden hast. Deine Daten sind sicher bei uns eingegangen. Eine Unterschrift darüber hinaus ist nicht
          erforderlich.<br />
          <br />
          Wir kümmern uns um den Rest. Deinen Versicherungsschein, Versicherungsbedingungen und Kundeninformation,
          Vertragsinformationen inkl. Widerrufsbelehrung, Datenschutzerklärung und Beratungsprotokoll senden wir nach
          Prüfung direkt an deine E-Mailadresse
          <b>{{ post.person.email }}</b
          >.<br />
          <br />
          Im Versicherungsschein findest du deine Versicherungsnummer, die du im Schadensfall benötigst. Bewahre daher
          deinen Versicherungsschein gut auf.
        </p>
        <img src="includes/media/icon_success.svg" class="success-image" />
        <br />
        <br />
        <hr class="divider--white" />
        <!-- input type="button" @click="push2astep(3)" class="ruv-btn" value="Surück!" -->
      </div>
    </div>
  </div>
  <div class="astep astep--6" v-if="astep == 6">
    <!-- Erstinformationen -->
    <div class="ruv-preconsent__wrapper">
      <div id="ruv-preconsent" class="ruv-preconsent">
        <div class="ruv-preconsent__wrap">
          <p class="txt--small"><strong>Willkommen bei der assona – wir sind Partner der R+V!</strong></p>
          <p class="txt--small">
            Du wurdest zu uns weitergeleitet, da uns die R+V als Versicherungsvermittler beauftragt hat.
          </p>
          <label class="txt--small">
            <input type="checkbox" v-model="preconsentcheck" id="preconsent" required />
            <label for="preconsent">Ich bestätige, die Erstinformationen heruntergeladen und gelesen zu haben.</label>
            <p v-show="errors.preconsentcheck" class="errors">
              <span class="errors--0 txt--small"
                >Bitte bestätige, dass du die Dokumente erhalten und gelesen hast.</span
              >
            </p>
          </label>
          <div class="downloads">
            <a
              href="docs/Erstinformation_Hardware-Versicherung.pdf"
              target="_blank"
              download
              class="download txt--small"
              >Erstinformationen herunterladen</a
            >
          </div>
          <p class="txt--small">
            Du möchtest die Erstinformation in Papierform? Gerne schicken wir dir diese per Post zu. Bitte wende dich
            hierfür an uns.
          </p>
          <address class="txt--small">
            assona GmbH, Postfach 51 11 36, 13371 Berlin<br />
            Telefon: <a href="tel:030 208 666 10">030 208 666 10</a><br />
            E-Mail: <a href="mailto:kundenservice@assona.com">kundenservice@assona.com</a>
          </address>
          <p class="txt--small">Vielen Dank.</p>
          <input
            type="button"
            class="ruv-btn txt--small"
            @click="preconsens(false)"
            name="preconsenssubmit"
            value="Jetzt Tarif berechnen" />
        </div>
      </div>
    </div>
  </div>
  <div class="astep astep--7" v-if="astep == 7">
    <!-- Fehlerseite -->
    <div class="section">
      <h3 class="intertitle c--white">
        <br />
        <br />
        <br />
        <br />
        Hoppla - da ist etwas schiefgelaufen.
      </h3>
      <div class="wrap txt--center">
        <p class="c--white txt--small">
          Wir haben im Moment technische Probleme. Bitte versuche es später noch einmal.
          <br />
          <br />
          <br />
          <br />
        </p>
        <!-- hr class="divider--white" -->
        <!-- input type="button" @click="push2astep(3)" class="ruv-btn" value="Surück!" -->
      </div>
    </div>
  </div>
</template>

<script>
import { GDialog } from 'gitart-vue-dialog';
import Datepicker from 'vue3-datepicker';
import axios from 'axios';
import { de } from 'date-fns/locale';

export default {
  name: 'ESport',
  props: {
    msg: String,
  },
  components: {
    Datepicker,
    GDialog,
  },
  data() {
    return {
      locale: de,
      astep: -1,
      backend: 'http://localhost',
      errors: {
        preconsentcheck: false,
        vorname: false,
        nachname: false,
        strasse: false,
        nummer: false,
        plz: false,
        ort: false,
        nationality: false,
        email: false,
        fullname: false,
        iban: false,
        ibaninvalid: false,
        sepa: false,
      },
      encryption: {
        secret: null,
        id: 0,
      },
      rework: false,
      preconsentcheck: false,
      diverse: true,
      showCasualModal: false,
      showHardcoreModal: false,
      showProfessionalModal: false,
      showIbanModal: false,
      message: 'Nachricht',
      nationalities: {
        DE: 'deutsch',
        AT: 'östereichisch',
        CH: 'schweizerisch',
        US: 'amerikanisch',
        XX: 'andere',
        AU: 'australisch',
        BE: 'belgisch',
        BR: 'brasilianisch',
        GB: 'britisch',
        BG: 'bulgarisch',
        CN: 'chinesisch',
        DK: 'dänisch',
        EE: 'estnisch',
        FI: 'finnisch',
        FR: 'französisch',
        FP: 'französisch-polynesisch',
        GE: 'georgisch',
        GR: 'griechisch',
        NL: 'holländisch',
        IN: 'indisch',
        IE: 'irisch',
        IS: 'isländisch',
        IL: 'israelisch',
        IT: 'italienisch',
        JP: 'japanisch',
        CA: 'kanadisch',
        HR: 'kroatisch',
        LV: 'lettisch',
        LI: 'liechtensteinisch',
        LT: 'litauisch',
        LU: 'luxemburgisch',
        MT: 'maltesisch',
        MX: 'mexikanisch',
        MC: 'monegassisch',
        NZ: 'neuseeländisch',
        NO: 'norwegisch',
        PL: 'polnisch',
        PT: 'portugiesisch',
        RO: 'romänisch',
        SE: 'schwedisch',
        UA: 'ukrainisch',
      },
      post: {
        payment: {
          fullname: '',
          month_interval: '2',
          iban: '',
          sepa: false,
        },
        product: {
          purchaseprice: 0,
          coverage: 0,
          coverageStartDate: null,
          product_id: 0,
          vtype: 'casual',
          vadds: {
            technology: false,
            verschleiss: false,
            transport: false,
            handhabung: false,
          },
        },
        person: {
          salutation: 'Herr',
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          street: '',
          houseNumber: '',
          city: '',
          zipCode: '',
          nationality: '',
          email: '',
          phoneNumber: '',
        },
      },
      rates: {
        casual: {
          basic: '7,99',
          technology: '1,00',
          verschleiss: '2,00',
          transport: '2,50',
          handhabung: '0,50',
        },
        hardcore: {
          basic: '17,99',
          technology: '2,00',
          verschleiss: '4,00',
          transport: '5,00',
          handhabung: '1,00',
        },
        professional: {
          basic: '45,99',
          technology: '4,00',
          verschleiss: '9,00',
          transport: '13,50',
          handhabung: '2,00',
        },
      },
      dates: {
        insurance_from: new Date(),
        insurance_to: null,
        birthdate_to: new Date(),
        coverageStartDate: null,
      },
      coverage: {
        casual: '1.500',
        hardcore: '5.000',
        professional: '15.000',
        chair: '1000',

        technology: '200',
        verschleiss: '150',
        transport: '120',
        handhabung: '100',
      },
    };
  },
  methods: {
    birthdayclick() {
      window._paq.push(['trackEvent', 'Interaktion', 'Interaktion Geburtsdatum']);
    },
    eqipmentclick(string, value) {
      console.log(!value);
      switch (string) {
        case 'technology': // Tarifrechnereinstieg = Aufrufe Seite "Tarifrechner"
          window._paq.push(['trackEvent', 'Interaktion', 'Interaktion Zusatzbaustein Tech-Upgrade']);
          break;
        case 'verschleiss': // Tarifrechnereinstieg = Aufrufe Seite "Tarifrechner"
          window._paq.push(['trackEvent', 'Interaktion', 'Interaktion Zusatzbaustein Verschleiss']);
          break;
        case 'transport': // Tarifrechnereinstieg = Aufrufe Seite "Tarifrechner"
          window._paq.push(['trackEvent', 'Interaktion', 'Interaktion Zusatzbaustein Transport']);
          break;
        case 'handhabung': // Tarifrechnereinstieg = Aufrufe Seite "Tarifrechner"
          window._paq.push(['trackEvent', 'Interaktion', 'Interaktion Zusatzbaustein Unsachgemaesse Handhabung']);
          break;
      }
    },
    reworkdata() {
      let self = this;
      window._paq.push(['trackPageView', 'Erneutes Bearbeiten der Erstinformationen']);
      self.rework = 'true';
      self.astep = 0;
    },
    ucfirst(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return;
      }
    },
    goto(refName) {
      setTimeout(function () {
        var element = window.$('#' + refName); //this.$refs[refName];
        var headerH = window.$('#header').height();
        var top = element.offset().top - headerH + 70;
        window.scrollTo(0, top);
      }, 55);
      setTimeout(function () {
        var element = window.$('#' + refName); //this.$refs[refName];
        var headerH = window.$('#header').height();
        var top = element.offset().top - headerH + 70;
        window.scrollTo(0, top);
      }, 100);
    },
    push2astep(astep) {
      let self = this;
      self.astep = astep;
      window.scrollTo(0, 0);
      //*/
      switch (astep) {
        case 0: // Tarifrechnereinstieg = Aufrufe Seite "Tarifrechner"
          window._paq.push(['trackPageView', 'Tarifrechnereinstieg']);
          break;
        case 1: // Danksagung = Event: Klicks auf Button "E-Mail bestätigen"
          window._paq.push(['trackPageView', 'E-Mail verschickt Dankesseite']);
          break;
        case 2: // Zahlungsdaten = Aufrufe Seite “Kontodaten und Zusammenfassung“
          window._paq.push(['trackPageView', 'Zahlungsdaten']);
          break;
        case 3: // Übersicht/Zusammenfassung = Event: Klicks auf Button "Zusammenfassung"
          window._paq.push(['trackPageView', 'Übersicht/Zusammenfassung']);
          break;
        case 4:
          //window._paq.push(['trackPageView', 'Beitragspflichtig abschließen']);
          break;
        case 5: // Bestätigungsseite = Event: Klicks auf Button "Beitragspflichtig abschließen"
          window._paq.push(['trackPageView', 'Bestätigungsseite']);
          break;
        case 6: // Bestätigung Erstinformation = Klick auf Button „Jetzt Tarif berechnen“
          window._paq.push(['trackPageView', 'Bestätigung Erstinformation']);
          break;
      }
      //*/
    },
    formatDate(date) {
      var MyDate = new Date(date);
      var MyDateString;
      MyDateString =
        ('0' + MyDate.getDate()).slice(-2) +
        '.' +
        ('0' + (MyDate.getMonth() + 1)).slice(-2) +
        '.' +
        MyDate.getFullYear();
      return MyDateString;
    },
    formatDimensionDate(date) {
      var MyDate = new Date(date);
      var MyDateString;
      MyDateString =
        +('0' + MyDate.getDate()).slice(-2) +
        '.' +
        ('0' + (MyDate.getMonth() + 1)).slice(-2) +
        '.' +
        MyDate.getFullYear() +
        '';
      return MyDateString;
    },
    getRatesAndCoverage() {
      let self = this;
      axios
        .get(self.backend + '/settings/coverage')
        .then((response) => {
          self.coverage = response.data;
          if (self.coverage.it_all_shiny_captain != 1) {
            self.push2astep(7);
          }
          axios
            .get(self.backend + '/settings/rates')
            .then((response) => {
              self.rates = response.data;
            })
            .catch((err) => {
              console.log(err.data.message);
            });
        })
        .catch((err) => {
          console.log(err.data);
          if (self.coverage.it_all_shiny_captain != 1) {
            self.push2astep(7);
          }
        });
    },
    setDates() {
      let self = this;
      var to = new Date();
      var min_birthdate = new Date();
      var from = new Date();
      var coverageStartDatestart = new Date(from.getFullYear(), from.getMonth() + 1, 1);
      var start = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1);
      to.setDate(to.getDate() + 364);
      from.setDate(from.getDate() + 1);
      start.setDate(start.getDate());
      this.dates.insurance_to = to;
      this.dates.insurance_from = start;
      this.dates.coverageStartDate = coverageStartDatestart;
      this.post.product.coverageStartDate = this.formatDate(start);
      min_birthdate.setFullYear(min_birthdate.getFullYear() - 18);
      this.dates.birthdate_to = min_birthdate;
      if (self.post.person.dateOfBirth == '') {
        this.dates.birthdate = min_birthdate;
      } else {
        var dateParts = self.post.person.dateOfBirth.split('.');
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        self.dates.birthdate = dateObject;
      }
    },
    productid() {
      let self = this;
      return new Promise((resolve) => {
        axios
          .post(self.backend + '/productid', self.post.product)
          .then((response) => {
            self.post.product.product_id = response.data.product_id;
            resolve(response.data.product_id);
          })
          .catch((err) => {
            console.log(err.data);
            resolve('rejected');
          });
      });
    },
    async submit(event) {
      let self = this;
      if (!self.$refs.form.vorname.checkValidity()) {
        self.errors.vorname = true;
      } else {
        self.errors.vorname = false;
      }
      if (!self.$refs.form.nachname.checkValidity()) {
        self.errors.nachname = true;
      } else {
        self.errors.nachname = false;
      }
      if (!self.$refs.form.strasse.checkValidity()) {
        self.errors.strasse = true;
      } else {
        self.errors.strasse = false;
      }
      if (!self.$refs.form.nummer.checkValidity()) {
        self.errors.nummer = true;
      } else {
        self.errors.nummer = false;
      }
      if (!self.$refs.form.plz.checkValidity()) {
        self.errors.plz = true;
      } else {
        self.errors.plz = false;
      }
      if (!self.$refs.form.ort.checkValidity()) {
        self.errors.ort = true;
      } else {
        self.errors.ort = false;
      }
      //*
      if (!self.$refs.form.nationality.checkValidity()) {
        self.errors.nationality = true;
      } else {
        self.errors.nationality = false;
      }
      //*/
      if (!self.$refs.form.email.checkValidity()) {
        self.errors.email = true;
      } else {
        self.errors.email = false;
      }
      if (this.$refs.form.checkValidity()) {
        window.goal(3);
        window._paq.push(['FormAnalytics::trackFormSubmit', document.getElementById('Tarifrechner')]);
        /*
        1='Buttonklick Jetzt Tarif berechnen'
        2='Aufruf Tarifrechner'
        3='Buttonklick E-Mail bestätigen'
        4='Aufruf Kontodaten'
        5='Buttonklick Zusammenfassung'
        6='Buttonklick Beitragspflichtig abschließen'
        */
        event.preventDefault();
        self.post.product.coverageStartDate = this.formatDate(self.dates.coverageStartDate);
        self.post.person.dateOfBirth = this.formatDate(self.dates.birthdate);
        self.post.product.product_id = await self.productid();
        self.push2astep(1);
        axios
          .post(self.backend + '/register/', self.post)
          .then((response) => {
            self.message = response.data.message;
            self.encryption = response.data.encryption;
            if (self.message == 'debug') {
              //self.push2astep(2);
              //self.proceed(self.debug.id, self.debug.secret);
            }
          })
          .catch((err) => {
            console.log(err.data);
          });
      } else {
        console.log('Form invalid!');
      }
    },
    parsegermanDate(input) {
      var parts = input.match(/(\d+)/g);
      // note parts[1]-1
      return new Date(parts[2], parts[1] - 1, parts[0]);
    },
    proceed(theid, thesecret) {
      let self = this;
      //*
      self.encryption.id = theid;
      self.encryption.secret = thesecret;
      //
      console.log(self.backend);
      axios
        .get(self.backend + '/proceed?id=' + theid + '&secret=' + thesecret)
        .then((response) => {
          if (response.data.message == 'data_missing' || response.data.message == 'dataset_not_found') {
            self.setDates();
            self.getRatesAndCoverage();
            self.push2astep(0);
            return;
          } else {
            self.setDates();
            self.post.person = response.data.person;
            self.post.product = response.data.product;
            self.post.payment = response.data.payment;
            self.post.payment.fullname = self.post.person.firstName + ' ' + self.post.person.lastName;
            self.dates.coverageStartDate = self.parsegermanDate(self.post.product.coverageStartDate);
            self.getRatesAndCoverage();
            self.push2astep(2);
            window.goal(4);
            /*
              1='Buttonklick Jetzt Tarif berechnen'
              2='Aufruf Tarifrechner'
              3='Buttonklick E-Mail bestätigen'
              4='Aufruf Kontodaten'
              5='Buttonklick Zusammenfassung'
              6='Buttonklick Beitragspflichtig abschließen'
              */
          }
        })
        .catch((err) => {
          console.log(err.data);
          console.log(err);
        });
      //*/
    },
    checkiban() {
      let self = this;
      if (self.post.payment.fullname == '') {
        self.errors.fullname = true;
        return false;
      } else {
        self.errors.fullname = false;
      }
      if (self.post.payment.iban == '') {
        self.errors.iban = true;
        return false;
      } else {
        self.errors.iban = false;
      }
      if (!self.post.payment.sepa) {
        self.errors.sepa = true;
        return false;
      } else {
        self.errors.sepa = false;
      }
      if (window.IBAN.isValid(self.post.payment.iban)) {
        self.errors.ibaninvalid = false;
      } else {
        self.errors.ibaninvalid = true;
        return false;
      }
      if (window.IBAN.isValid(self.post.payment.iban) && self.post.payment.fullname != '' && self.post.payment.sepa) {
        self.push2astep(3);
        window.goal(5);
        /*
        1='Buttonklick Jetzt Tarif berechnen'
        2='Aufruf Tarifrechner'
        3='Buttonklick E-Mail bestätigen'
        4='Aufruf Kontodaten'
        5='Buttonklick Zusammenfassung'
        6='Buttonklick Beitragspflichtig abschließen'
        */
        return false;
      } else {
        self.showIbanModal = true;
        return false;
      }
    },
    sealthedeal() {
      let self = this;
      self.push2astep(4);
      self.post.id = self.encryption.id;
      self.post.secret = self.encryption.secret;
      window._paq.push(['setCustomDimension', 1, self.ucfirst(self.post.product.vtype)]);

      var coverageStartDate = self.formatDimensionDate(self.dates.coverageStartDate) + '';
      window._paq.push(['setCustomDimension', 4, coverageStartDate]);

      var zahlungsintervall = '';
      if (self.post.payment.month_interval == 2) {
        zahlungsintervall = 'monatlich';
      }
      if (self.post.payment.month_interval == 3) {
        zahlungsintervall = 'vierteljährlich';
      }
      if (self.post.payment.month_interval == 4) {
        zahlungsintervall = 'halbjährlich';
      }
      if (self.post.payment.month_interval == 5) {
        zahlungsintervall = 'jährlich';
      }
      window._paq.push(['setCustomDimension', 3, zahlungsintervall]);

      var servicebool = ' ';
      servicebool =
        servicebool +
        (self.post.product.vadds.transport ? 'TRA ' : '') +
        (self.post.product.vadds.verschleiss ? 'WEA ' : '') +
        (self.post.product.vadds.technology ? 'TEC ' : '') +
        (self.post.product.vadds.handhabung ? 'INH ' : '');
      window._paq.push(['setCustomDimension', 2, servicebool]);

      axios
        .post(self.backend + '/send/deal', self.post)
        .then((response) => {
          self.message = response.data.message;
          if (response.data.message == 'wrong_email' || response.data.message == 'customer_data_missing') {
            self.push2astep(0);
          } else {
            window.goal(6);
            /*
                1='Buttonklick Jetzt Tarif berechnen'
                2='Aufruf Tarifrechner'
                3='Buttonklick E-Mail bestätigen'
                4='Aufruf Kontodaten'
                5='Buttonklick Zusammenfassung'
                6='Buttonklick Beitragspflichtig abschließen'
                */
            self.push2astep(5);
          }
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    TomorowTimestamp: function () {
      var time = Date.now();
      time = time + 86400;
      return time;
    },
    preconsens(get = false) {
      let self = this;
      if (get) {
        let name = 'preconsens=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return false;
      }
      if (self.preconsentcheck) {
        var cname = 'preconsens';
        var cvalue = 'Given';
        const d = new Date();
        d.setTime(d.getTime() + 3 * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
        self.errors.preconsentcheck = false;
        this.setDates(), this.getRatesAndCoverage(), window.goal(1);
        /*
        1='Buttonklick Jetzt Tarif berechnen'
        2='Aufruf Tarifrechner'
        3='Buttonklick E-Mail bestätigen'
        4='Aufruf Kontodaten'
        5='Buttonklick Zusammenfassung'
        6='Buttonklick Beitragspflichtig abschließen'
        */
        self.push2astep(0);
        window.goal(2);
      } else {
        self.errors.preconsentcheck = true;
        //window.goal(2);
        /*
        1='Buttonklick Jetzt Tarif berechnen'
        2='Aufruf Tarifrechner'
        3='Buttonklick E-Mail bestätigen'
        4='Aufruf Kontodaten'
        5='Buttonklick Zusammenfassung'
        6='Buttonklick Beitragspflichtig abschließen'
        */
        self.push2astep(6);
      }
    },
  },
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    let self = this;
    self.backend = `${window.location.protocol}//${window.location.hostname}`;
    self.getRatesAndCoverage();
    if (self.preconsens(true) != 'Given') {
      self.push2astep(6);
      return;
    }

    if (urlParams.has('id') && urlParams.has('secret')) {
      this.secret = urlParams.get('secret');
      this.id = urlParams.get('id');
      this.proceed(this.id, this.secret);
    } else {
      window.goal(1);
      /*
      1='Buttonklick Jetzt Tarif berechnen'
      2='Aufruf Tarifrechner'
      3='Buttonklick E-Mail bestätigen'
      4='Aufruf Kontodaten'
      5='Buttonklick Zusammenfassung'
      6='Buttonklick Beitragspflichtig abschließen'
      */

      this.setDates(), self.push2astep(0);
    }
  },
  computed: {
    // a computed getter
    getPriceAmount() {
      let self = this;
      self.post.product.coverage = self.coverage[self.post.product.vtype].replace(/,/g, '.') * 1000;
      self.post.product.purchaseprice = 0;
      self.post.product.purchaseprice = self.rates[self.post.product.vtype].basic.replace(/,/g, '.') * 1;
      //*
      var adds = self.post.product.vadds;
      for (const [key, value] of Object.entries(adds)) {
        if (value) {
          self.post.product.purchaseprice =
            self.post.product.purchaseprice + self.rates[self.post.product.vtype][key].replace(/,/g, '.') * 1;
        }
      }
      //*/
      //TODO Return VJ HJ J
      /*
      2 Mntl
      3 VJ
      4 HJ
      5 J
      */
      if (self.post.payment.month_interval == 2) {
        return Number(self.post.product.purchaseprice).toFixed([2]).replace('.', ',');
      }
      if (self.post.payment.month_interval == 3) {
        return Number(self.post.product.purchaseprice * 3)
          .toFixed([2])
          .replace('.', ',');
      }
      if (self.post.payment.month_interval == 4) {
        return Number(self.post.product.purchaseprice * 6)
          .toFixed([2])
          .replace('.', ',');
      }
      if (self.post.payment.month_interval == 5) {
        return Number(self.post.product.purchaseprice * 12)
          .toFixed([2])
          .replace('.', ',');
      }
      return Number(self.post.product.purchaseprice).toFixed([2]).replace('.', ',');
    },
  },
};
</script>

<style>
.astep--4 {
  text-align: center;
}
.toCapitalFirst {
  text-transform: capitalize;
}
.hidden {
  display: none;
}
.show {
  display: inline-block !important;
}
.ruv_datpicker {
  font-size: 0.9rem;
}
.v3dp__popout {
  position: fixed;
  display: block;
  margin-top: 10%;
  margin-left: 50%;
  top: 10px;
  left: -8.75em;
}
.fordatepicker {
  position: relative;
  top: -32px;
  left: 97px;
  z-index: -1;
  color: #ffffff;
}
.modal-wrapper {
  color: #000;
  padding: 5px;
}

.modal-title {
  color: var(--blue);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.modal-button {
  color: var(--blue);
  background-color: var(--green);
  border: 0;
  padding: 0.5rem 0.8rem;
}

.modal-content {
  color: var(--blue);
  padding: 20px;
}

.modal-actions {
  float: right;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 0;
}
@media screen and (min-width: 1080px) {
  .sidebar__price .txt--small {
    vertical-align: top;
    margin-right: 0;
  }
}
</style>
